import { codeSplit, Controller, FetchOptions, FetchPageOptions } from 'bernie-core';
import { Localization } from 'bernie-l10n';
import { PageData } from 'bernie-http';
import '../../client/temp-common-imports';
import { ChallengeRoutes, BASE_CHALLENGE_PATH } from '../routes/challenges';
import { CONTROLLER_EVENTS } from '../events/controller-events';

export class ArkoseChallengeLite implements Controller {
  public pageId = 'arkose-challenge-lite';
  public path = `${BASE_CHALLENGE_PATH}/${ChallengeRoutes.ARKOSE_LITE}`;
  public routeName = ChallengeRoutes.ARKOSE_LITE;
  public bundles = ['captcha-challenge'];

  public component = codeSplit(() => import(/* webpackChunkName: "captcha-challenge" */ '../../views/challenge'));
  public exact = true;

  // tslint:disable-next-line:prefer-function-over-method
  public fetch(options: FetchOptions) {
    if (options && options.logger && options.isServer) {
      options.logger.logEvent(CONTROLLER_EVENTS.get(this.pageId));
    }

    return Promise.resolve({}); // nothing to do
  }

  // tslint:disable-next-line:prefer-function-over-method
  public fetchPageData(pageData: FetchPageOptions): Promise<PageData> {
    const loc = new Localization(pageData.context.locale);

    return Promise.resolve({ title: loc.formatText('error_pageheader') }) as Promise<PageData>;
  }
}

export default ArkoseChallengeLite;
