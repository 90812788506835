import '../../client/temp-common-imports';
import ChallengeController from './common/index';
import { ChallengeRoutes, BASE_CHALLENGE_PATH } from '../routes/challenges';

export class GCaptchaV2 extends ChallengeController {
  public path = `${BASE_CHALLENGE_PATH}/${ChallengeRoutes.GCAPTCHAV2}`;
  public routeName = ChallengeRoutes.GCAPTCHAV2;
  public pageId = 'g-captcha-v2';
}

export default GCaptchaV2;
