import { matchPath } from 'react-router-dom-v5-compat';
import { BASE_CHALLENGE_PATH, ChallengeRoutes, ChallengeRoutePaths } from '../routes/challenges';

export const getChallengeFromPath = (path: string): ChallengeRoutes => {
  if (!path.startsWith(BASE_CHALLENGE_PATH)) {
    return null;
  }
  const match = matchPath(`${BASE_CHALLENGE_PATH}/:challenge/*`, path);
  const challenge = match && match.params && (match.params.challenge as ChallengeRoutes);
  const whichChallenge: ChallengeRoutes =
    (ChallengeRoutePaths.includes(challenge) && challenge) || ChallengeRoutes.ARKOSE;

  return whichChallenge;
};

export default getChallengeFromPath;
