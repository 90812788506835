export const BASE_CHALLENGE_PATH = '/botOrNot';

export enum ChallengeRoutes {
  GCAPTCHAV2 = 'g-captcha-v2',
  ARKOSE_LITE = 'arkose-challenge-lite',
  ARKOSE = 'arkose-challenge',
  POW = 'pow-challenge'
}

export const ChallengeRoutePaths: string[] = Object.values(ChallengeRoutes);

export type TChallengeRouteMap = {
  [value in ChallengeRoutes]: string;
};
