import '../../client/temp-common-imports';
import ChallengeController from './common/index';

export class RootChallenge extends ChallengeController {
  public pageId = 'wildcard-challenge-handler';
  /*
    This path MUST match literally anything to enable client-side hydration to work properly.
    CGP does a rewrite to the path matched by the various challenge controllers, which allows it to
    render correctly server-side, but that path is not reflected in the client for hydrate. This controller
    is the one which ends up handling client-side renders.
  */
  public path = '/*';
  public routeName = 'wildcard-challenge-handler';
  public exact = true;
}

export default RootChallenge;
