import '../../client/temp-common-imports';
import ChallengeController from './common/index';
import { ChallengeRoutes, BASE_CHALLENGE_PATH } from '../routes/challenges';

export class ArkoseChallenge extends ChallengeController {
  public path = `${BASE_CHALLENGE_PATH}/${ChallengeRoutes.ARKOSE}`;
  public routeName = ChallengeRoutes.ARKOSE;
  public pageId = 'arkose-challenge';
}

export default ArkoseChallenge;
