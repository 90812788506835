import { Store } from 'bernie-plugin-mobx';
import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';

import { Buffer } from 'buffer';
import { ChallengeRoutes } from '../controllers/routes';

export class BotOrNotStore extends Store {
  public constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
  }

  public readonly blocksGoogle: boolean;
  public readonly siteKey: string;
  public readonly destination: string;
  public decodedDestination: string;
  public readonly powComplexity: string;
  public readonly noncePrefix: string;
  public whichChallenge: ChallengeRoutes;
  public validatePath: string;
  public readonly clientApiUrl: string;
  public readonly nojsClientApiUrl: string;
  public readonly arkoseDataExchange: string;

  private setGCaptchaDomain() {
    if (typeof document !== 'undefined') {
      const w = window as any;
      w.recaptchaOptions = {
        useRecaptchaNet: true
      };
    }
  }

  public hydrate(data: SerializedData): void {
    Object.assign(this, data);
    this.decodedDestination = Buffer.from(data.destination, 'base64').toString('utf8');

    if (data.blocksGoogle) {
      this.setGCaptchaDomain();
    }
  }
}
